class QuickLook {
  constructor(api) {
    this.api = api;
    this.analysisType = null;
    this.processing = false;
    this.buttonText = "";
    this.resultUrl = "";
    this.taskId = null;
    this.sharedAnalysisUrl = "";
    this.sharePopupOpen = false;

    this.quicklookIntervalId = null;
    this.resetButtonText = "";
    this.readyButtonText = "";

    this.quickLookTypes = {};
  }

  async reset() {
    this.processing = false;
    this.buttonText = this.resetButtonText;
    this.resultUrl = "";
    this.taskId = null;
    this.sharedAnalysisUrl = "";
    this.sharePopupOpen = false;
  }

  async submit() {}

  async checkStatus() {
    this.quicklookIntervalId = setInterval(async () => {
      const response = await this.api.get(`tasks/worker/status/${this.taskId}`);
      if (response.state === "success") {
        clearInterval(this.quicklookIntervalId);
        await this.taskReady();
      } else if (response.state === "failure") {
        alert(`Task with ID ${this.taskId} failed. Contact admin.`);
        await this.reset();
        clearInterval(this.quicklookIntervalId);
      }
    }, 2000);
  }

  async taskReady() {
    this.processing = false;
    this.buttonText = this.readyButtonText;
    this.resultUrl = `${this.api.apiRoute}/tasks/worker/quicklook/${this.taskId}`;
  }

  async shareQuickLook() {
    const response = await this.api.get(
      `tasks/worker/quicklook/share/${this.taskId}`
    );
    this.sharedAnalysisUrl = response.shareable_link;
    this.sharePopupOpen = true;
  }
}

export class PPGQuickLook extends QuickLook {
  constructor(api) {
    super(api);
    this.buttonText = "Submit QuickLook";
    this.resetButtonText = "Submit QuickLook";
    this.readyButtonText = "View Analysis";

    this.quickLookTypes = {
      ppg_timeseries_ql: "Basic QuickLook",
      ppg_auc_annotated_ql: "AUC Annotated QuickLook",
    };
  }

  async submit(uuids) {
    if (uuids.length > 10) {
      alert("More than 10 PPGs selected, currently not supported");
      return;
    }
    this.processing = true;
    this.buttonText = "Processing";

    const response = await this.api.post("tasks/worker/quicklook/ppg", {
      task: this.analysisType,
      uuids: uuids,
    });
    this.taskId = response.task_id;
    await this.checkStatus();
  }
}

class PatientQuickLook extends QuickLook {
  constructor(api) {
    super(api);
    this.quickLookTypes = {
      hf_dri_timeseries_ql: "Seerlinq HeartCore",
      heartcore_report: "HeartCore Monthly Report",
    };
  }

  async submit(patient_ids) {
    this.processing = true;
    this.buttonText = "Processing";

    const response = await this.api.post("tasks/worker/quicklook/patient", {
      task: this.analysisType,
      patient_ids: patient_ids,
    });
    this.taskId = response.task_id;
    await this.checkStatus();
  }
}

export class SeerlinqDRIHeartCore extends PatientQuickLook {
  constructor(api) {
    super(api);
    this.buttonText = "DRI HeartCore";
    this.analysisType = "hf_dri_timeseries_ql";
    this.resetButtonText = "DRI HeartCore";
    this.readyButtonText = "View HeartCore";
  }
}

export class SeerlinqHCReport extends PatientQuickLook {
  constructor(api) {
    super(api);
    this.buttonText = "HeartCore Report";
    this.analysisType = "heartcore_report";
    this.resetButtonText = "HeartCore Report";
    this.readyButtonText = "View Report";
  }
}
