import { parseDatetimeToLocal } from "./utils";
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'chartjs-adapter-moment';

class SimplePlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    this.data = dataModel;
    this.valueKey = "";
    this.filterKey = "";
    this.dateTimeKey = "";
    this.chartId = chartId;
    this.hueKey = null;
    this.styleKey = null;
    this.plotType = null;
    this.plotStart = null;
    this.plotEnd = null;
    this.plotTimeSeries = null;
    this.chart = null;
    this.showLegend = false;

    this.defaultColor = "rgba(187, 22, 163, 1)";
    this.defaultStyle = "circle";
    this.extraFilter = {};

    this.minDataPoints = 2;
    this.plotTypeOptions = [];
    this.defaultPlotTypeOptions = plotTypeOptions;
  }

  async initPlot() {
    this.plotTypeOptions = [];
    for (let plotOption of this.defaultPlotTypeOptions) {
      const filter = {};
      filter[this.filterKey] = [plotOption];
      for (const key in this.extraFilter) {
        filter[key] = this.extraFilter[key];
      }
      const filteredData = await this.data.sortAndFilterCustom(
        [this.dateTimeKey],
        [false],
        filter
      );
      if (filteredData.length >= this.minDataPoints) {
        this.plotTypeOptions.push(plotOption);
      }
    }
  }

  async resetPlot() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = null;
  }

  async preparePlot() {
    await this.resetPlot();
    const filter = {};
    filter[this.filterKey] = [this.plotType];
    for (const key in this.extraFilter) {
      filter[key] = this.extraFilter[key];
    }

    const filteredData = await this.data.sortAndFilterCustom(
      [this.dateTimeKey],
      [false],
      filter
    );
    this.plotStart = await parseDatetimeToLocal(
      new Date(
        Math.min(
          ...filteredData.map((item) => new Date(item[this.dateTimeKey]))
        )
      )
    );
    this.plotEnd = await parseDatetimeToLocal(
      new Date(
        Math.max(
          ...filteredData.map((item) => new Date(item[this.dateTimeKey]))
        )
      )
    );
    this.plotTimeSeries = filteredData;
    await this.updatePlot(this.plotStart, this.plotEnd);
  }

  async yLabel(plotType) {
    return plotType;
  }

  async getChartScales() {
    return {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM D",
          },
        },
        adapters: {
          date: {
            library: moment,
          },
        },
        title: {
          display: true,
          text: "date",
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: await this.yLabel(this.plotType),
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
    };
  }

  async getDatesTimeSeries(minDate, maxDate) {
    const filtPlotEnd = new Date(maxDate);
    filtPlotEnd.setSeconds(filtPlotEnd.getSeconds() + 1);
    return this.plotTimeSeries.filter(
      (item) =>
        new Date(item[this.dateTimeKey]) >= new Date(minDate) &&
        new Date(item[this.dateTimeKey]) <= filtPlotEnd
    );
  }

  async chartData(plotData, color, style, label = null) {
    return {
      label: label,
      data: plotData.map((item) => ({
        x: item[this.dateTimeKey],
        y: item[this.valueKey],
      })),
      borderColor: color,
      backgroundColor: color,
      pointStyle: style,
      borderWidth: 0.5,
      fill: false,
      pointRadius: 5,
      pointHoverRadius: 7,
    };
  }

  async getDatasets(timeSeries) {
    return [
      await this.chartData(timeSeries, this.defaultColor, this.defaultStyle),
    ];
  }

  async updatePlot(minDate, maxDate) {
    await this.resetPlot();

    const timeSeries = await this.getDatesTimeSeries(minDate, maxDate);
    const ctx = document.getElementById(this.chartId).getContext("2d");
    const datasets = await this.getDatasets(timeSeries);
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          legend: {
            display: this.showLegend,
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: await this.getChartScales(),
      },
    });
  }
}

class SingleGroupedPlot extends SimplePlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);
    this.showLegend = true;
    this.hueKey = null;
    this.styleKey = null;

    // palettes
    this.colorPalette = [
      "rgba(187, 22, 163, 1)",
      "rgba(5, 195, 222, 1)",
      "rgba(37, 40, 42, 1)",
      "rgba(0, 146, 203, 1)",
      "rgba(112, 115, 114, 1)",
    ];
    this.stylePalette = ["circle", "triangle", "cross", "rect"];
  }

  async singleGroup(timeSeries, key) {
    return timeSeries.reduce((acc, item) => {
      if (!acc[item[key]]) {
        acc[item[key]] = [];
      }
      acc[item[key]].push(item);
      return acc;
    }, {});
  }

  async getDatasets(timeSeries) {
    if (this.hueKey && this.hueKey[this.plotType]) {
      const groupedData = await this.singleGroup(
        timeSeries,
        this.hueKey[this.plotType]
      );
      return await Promise.all(
        Object.keys(groupedData).map(async (key, index) => {
          const groupData = groupedData[key];
          const color = this.colorPalette[index % this.colorPalette.length];
          return await this.chartData(groupData, color, this.defaultStyle, key);
        })
      );
    } else if (this.styleKey && this.styleKey[this.plotType]) {
      const groupedData = await this.singleGroup(
        timeSeries,
        this.styleKey[this.plotType]
      );
      return await Promise.all(
        Object.keys(groupedData).map(async (key, index) => {
          const groupData = groupedData[key];
          const style = this.stylePalette[index % this.stylePalette.length];
          return await this.chartData(groupData, this.defaultColor, style, key);
        })
      );
    }
  }
}

class DoubleGroupedPlot extends SingleGroupedPlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);

    this.colorMapping = {};
    this.styleMapping = {};
  }

  async doubleGroup(timeSeries, key1, key2) {
    return timeSeries.reduce((acc, item) => {
      const key = `${item[key1]}_${item[key2]}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  async getDatasets(timeSeries) {
    const groupedData = await this.doubleGroup(
      timeSeries,
      this.hueKey[this.plotType],
      this.styleKey[this.plotType]
    );
    return await Promise.all(
      Object.keys(groupedData).map(async (key, index) => {
        const groupData = groupedData[key];
        const [hue, style] = key.split("_");
        return await this.chartData(
          groupData,
          this.colorMapping[hue],
          this.styleMapping[style],
          `${hue} - ${style}`
        );
      })
    );
  }
}

export class ComputedPlot extends DoubleGroupedPlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";
    this.hueKey = {
      "respiratory rate": ["ppg_conditions"],
      "heart rate": ["ppg_conditions"],
      "diastolic reserve index": ["ppg_conditions"],
    };
    this.styleKey = {
      "respiratory rate": ["tags"],
      "heart rate": ["tags"],
      "diastolic reserve index": ["tags"],
    };

    this.colorMapping = {
      "Condition 1": "rgba(187, 22, 163, 1)",
      "Condition 2": "rgba(5, 195, 222, 1)",
      "Condition 3": "rgba(37, 40, 42, 1)",
      "Condition 1,Condition 2": "rgba(187, 22, 163, 1)",
    };
    this.styleMapping = {
      ir: "circle",
      "ir,beats": "circle",
      "ir,beats,XB123EF1FM1": "circle",
      "ir,EMD": "triangle",
    };
  }
}

export class DerivedPlot extends SimplePlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";

    this.extraFilter = { rolling: ["full"] };
  }
}

export class MedicalPlot extends SimplePlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";
  }
}

export class SymptomPlot extends SimplePlot {
  constructor(dataModel, plotTypeOptions, chartId) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "symptom_value";
    this.filterKey = "symptom_name";
    this.dateTimeKey = "symptom_date";
  }
}
