import * as Sentry from '@sentry/browser';
import { envName } from './config';

export function initSentry() {
  try {
    Sentry.init({
      dsn: "https://273c38a86e9eaec1b5b4f1edb66dfb44@o4508094333190144.ingest.de.sentry.io/4508094336008272",
      environment: envName,
    });
  } catch (error) {
    console.error('Failed to init Sentry', error);
  }
}